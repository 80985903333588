import React, { useEffect, useMemo, useState } from 'react'
import { fieldsList } from './FormField'
import FormContent from '../form/Form'
import { useStoreViewContext } from './../../context/storeViewContext/StoreViewContext'
import { EnumConfigType, EnumOperateType } from '../../types/enums'
import apiCollection from '../../api'
import { IFromMapItem } from '../../types/interface'
import { message } from 'antd'
import { IGetEmployeeListRequest } from '../../api/apiModel/employee'

interface EmployeeDetailProps {
  isEdit?: boolean
  editData?: IGetEmployeeListRequest
  operateCallback: (type: EnumOperateType) => void
}

const EmployeeDetailContent = ({
  isEdit,
  editData,
  operateCallback,
}: EmployeeDetailProps) => {
  const { configMap } = useStoreViewContext()
  const [initData, setInitData] = useState(null)

  const optionsMap = useMemo(() => {
    return {
      regionList: configMap.get(EnumConfigType.Store_Region),
      positionList: configMap.get(EnumConfigType.Position),
    }
  }, [configMap])

  const formMap = useMemo((): IFromMapItem[] => {
    const fields =
      fieldsList?.map((item) =>
        item.name === 'Employee_No'
          ? { ...item, extra: { ...item.extra, disabled: isEdit } }
          : item
      ) ?? []
    return [
      {
        panelName: 'Employee',
        fieldsList: fields,
        showSubmitButton: true,
        showCancelButton: true,
      },
    ]
  }, [fieldsList, isEdit])

  const submitCallback = async (formData) => {
    const { Employee_No, Email } = formData
    formData.Email = Email ?? null
    const result = isEdit
      ? await apiCollection.updateEmployeeDetailInfo(Employee_No, formData)
      : await apiCollection.createEmployeeDetailInfo(formData)
    if (result.success) {
      operateCallback(EnumOperateType.Submit)
    } else {
      message.error(result.message)
      throw Error(result.message)
    }
  }

  const cancelCallback = () => {
    operateCallback(EnumOperateType.Cancel)
  }

  const initFormatData = () => {
    if (!editData || !Object.keys(editData).length) return

    setInitData(editData)
  }

  useEffect(() => {
    initFormatData()
  }, [editData])

  return (
    <>
      <FormContent
        formMap={formMap}
        optionsMap={optionsMap}
        isEdit={isEdit}
        initData={initData}
        formKeyName={initData?.Employee_No}
        submitCallback={submitCallback}
        cancelCallback={cancelCallback}
      />
    </>
  )
}

export default EmployeeDetailContent
