import { EnumFormFieldType } from '../../types/enums'
import { IFormField } from '../../types/interface'

export const fieldsList: IFormField[] = [
  {
    name: 'Region',
    label: 'Region',
    require: true,
    type: EnumFormFieldType.Select,
    optionsName: 'regionList',
    col: 12,
  },
  {
    name: 'Position',
    label: 'Position',
    require: true,
    type: EnumFormFieldType.Select,
    optionsName: 'positionList',
    col: 12,
  },
  {
    name: 'Employee_No',
    label: 'Staff No.',
    require: true,
    type: EnumFormFieldType.Input,
    col: 12,
    placeholder: 'only E R and number 0-9 are allowed',
    getValueFromEvent: (event) => {
      return event.target.value.replace(/[^0-9ER]+/g, '')
    },
  },
  {
    name: 'Name_CN',
    label: 'CN Name',
    require: true,
    type: EnumFormFieldType.Input,
    col: 12,
    placeholder: 'use English name if has no Chinese name',
    rules: [
      {
        pattern: /^[\u4e00-\u9fa5-&A-Za-z ]+$/,
        message: '请输入正确格式的姓名',
      },
      {
        validator: (_, value) => {
          if (!value) return Promise.resolve('')
          if (!value.trim())
            return Promise.reject(new Error(`中文名 为必填项！`))
          return Promise.resolve()
        },
      },
    ],
  },
  {
    name: 'Name_EN',
    label: 'Eng Name',
    require: true,
    type: EnumFormFieldType.Input,
    col: 12,
    getValueFromEvent: (event) => {
      return event.target.value.replace(/[^-&A-Za-z ]+/g, '')
    },
    rules: [
      {
        validator: (_, value) => {
          if (!value) return Promise.resolve('')
          if (!value.trim())
            return Promise.reject(new Error(`英文名 为必填项！`))
          return Promise.resolve()
        },
      },
    ],
  },
  {
    name: 'Email',
    label: 'Email',
    type: EnumFormFieldType.Input,
    col: 12,
    placeholder: 'Such as "someone@danielwellington.com"',
    rules: [
      {
        validator: (_, value) => {
          if (!value) return Promise.resolve('')
          if (!/\w+([-+.]\w+)*@danielwellington.com$/.test(value.trim()))
            return Promise.reject(new Error(`Please input correct email address! Such as "someone@danielwellington.com"`))
          return Promise.resolve()
        },
      },
    ],
  }
]

export const filterFields: IFormField[] = [
  {
    name: 'Name_CN',
    label: 'CN Name',
    type: EnumFormFieldType.Input,
    col: 12,
  },
  {
    name: 'Name_EN',
    label: 'Eng Name',
    type: EnumFormFieldType.Input,
    col: 12,
  },
  {
    name: 'Region',
    label: 'Region',
    type: EnumFormFieldType.Select,
    optionsName: 'regionList',
    col: 12,
  },
  {
    name: 'Position',
    label: 'Position',
    type: EnumFormFieldType.Select,
    optionsName: 'positionList',
    col: 12,
  },
  {
    name: 'Employee_No',
    label: 'Staff No.',
    type: EnumFormFieldType.Input,
  },
]
