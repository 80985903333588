import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { filterFields } from './FormField'
import FormContent from '../form/Form'
import { useStoreViewContext } from './../../context/storeViewContext/StoreViewContext'
import { EnumConfigType, EnumExcelType } from '../../types/enums'
import { IFromMapItem } from '../../types/interface'
import Download from './../icon/Download'
import IconContent from './../icon/IconContent'
import { PlusOutlined } from '@ant-design/icons'

const EmployeeFilterContainer = styled.div`
  padding-bottom: 4.8rem;
  position: relative;
`

interface IEmployeeFilterProps {
  filterData: object
  changeFilter: (filterData: object) => void
  addEmployee: () => void
}

const EmployeeFilterContent = ({
  filterData,
  changeFilter,
  addEmployee,
}: IEmployeeFilterProps) => {
  const { configMap } = useStoreViewContext()

  const optionsMap = useMemo(() => {
    return {
      regionList: configMap.get(EnumConfigType.Store_Region),
      positionList: configMap.get(EnumConfigType.Position),
    }
  }, [configMap])

  const formMap: IFromMapItem[] = [
    {
      panelName: 'Employee Filter',
      fieldsList: filterFields,
      showSubmitButton: true,
      submitButtonLabel: '搜索',
      cancelButtonLabel: '重置',
      buttonPosition: 'right',
      showCancelButton: true,
      customFormButton: [
        <Download fileUrl={`report/${EnumExcelType.StoreReport}`} />,
        <IconContent
          icon={PlusOutlined}
          tips={'新增员工'}
          clickCallback={addEmployee}
        />,
      ],
    },
  ]

  const resetFilter = () => {
    changeFilter({})
  }

  return (
    <EmployeeFilterContainer>
      <FormContent
        isEdit={true}
        initData={filterData}
        hiddenPanel={true}
        disableCatch={true}
        formMap={formMap}
        optionsMap={optionsMap}
        expandIcon={() => ''}
        submitCallback={changeFilter}
        cancelCallback={resetFilter}
      />
    </EmployeeFilterContainer>
  )
}

export default EmployeeFilterContent
