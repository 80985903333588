import React from 'react'
import { TablePaginationConfig } from 'antd'
import { useStoreViewContext } from './../../context/storeViewContext/StoreViewContext'
import { EnumConfigType } from '../../types/enums'
import { IGetEmployeeListRequest } from '../../api/apiModel/employee'
import { IPageInfo } from '../../types/interface'
import Table from '../tools/Table'

interface IEmployeeTableProps {
  list: IGetEmployeeListRequest[]
  total: number
  pageInfo: IPageInfo
  onPageChange: (props: { size: number; page: number }) => void
  onClickByEdit: (data: IGetEmployeeListRequest) => void
}

const EmployeeTable = ({
  list,
  total,
  pageInfo,
  onPageChange,
  onClickByEdit,
  ...props
}: IEmployeeTableProps) => {
  const { configMap } = useStoreViewContext()

  const columns = [
    {
      title: 'Staff No.',
      dataIndex: 'Employee_No',
      fixed: true,
      render: (text, item) => (
        <a
          onClick={(e) => {
            onClickByEdit(item)
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Region',
      dataIndex: 'Region',
      render: (id) => (
        <span>
          {
            configMap
              .get(EnumConfigType.Store_Region)
              ?.find((item) => item.Id === id)?.Value
          }
        </span>
      ),
    },
    {
      title: 'Position',
      dataIndex: 'Position',
      render: (id) => (
        <span>
          {
            configMap
              .get(EnumConfigType.Position)
              ?.find((item) => item.Id === id)?.Value
          }
        </span>
      ),
    },
    {
      title: 'CN Name',
      dataIndex: 'Name_CN',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Eng Name',
      dataIndex: 'Name_EN',
      render: (text) => <span>{text}</span>,
    },
  ]
  const data = list.map((item) => ({ ...item, key: item.Employee_No }))

  const onChangeByTable = (pagination: TablePaginationConfig) => {
    const { pageSize: size, current: page } = pagination
    if (pageInfo.size != size || pageInfo.page != page) {
      onPageChange({ size, page })
    }
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        onChange={onChangeByTable}
        pagination={{
          total,
          showTotal:(total) => `Total ${total} items`,
          current: pageInfo.page,
          pageSize: pageInfo.size,
          showSizeChanger: true,
        }}
      />
    </>
  )
}

export default EmployeeTable
