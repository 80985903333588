import React from 'react'
import { IGetEmployeeListRequest } from '../../api/apiModel/employee'
import EmployeeTable from './Table'
import EmployeeFilter from './Filter'
import { EnumOperateType } from '../../types/enums'
import { IOperateCallbackParams } from './Employee'
import { Spin } from 'antd'
import { IPageInfo } from '../../types/interface'

interface IEmployeeListProps {
  list: IGetEmployeeListRequest[]
  isLoading: boolean
  total: number
  pageInfo: IPageInfo
  filterData: object
  operateCallback: (
    type: EnumOperateType,
    item?: IOperateCallbackParams
  ) => void
}

const EmployeeListContent = ({
  list,
  total,
  pageInfo,
  isLoading,
  filterData,
  operateCallback,
}: IEmployeeListProps) => {
  const changeFilter = (data: object) => {
    const filter = {}
    for (const [key, value] of Object.entries(data)) {
      if (value) filter[key] = value
    }
    operateCallback(EnumOperateType.Filter, { filter })
  }

  return (
    <>
      <EmployeeFilter
        filterData={filterData}
        changeFilter={changeFilter}
        addEmployee={() => {
          operateCallback(EnumOperateType.Add)
        }}
      />
      <Spin spinning={isLoading} delay={300}>
        <EmployeeTable
          total={total}
          pageInfo={pageInfo}
          list={list}
          onPageChange={(pageInfo) =>
            operateCallback(EnumOperateType.ChangePage, { pageInfo })
          }
          onClickByEdit={(item) => {
            operateCallback(EnumOperateType.Update, { item })
          }}
        />
      </Spin>
    </>
  )
}

export default EmployeeListContent
