import React, { useEffect, useState } from 'react'
import EmployeeDetailContent from './Detail'
import EmployeeListContent from './List'
import apiCollection from '../../api'
import { IGetEmployeeListRequest } from '../../api/apiModel/employee'
import { EnumMenuTypes, EnumOperateType } from '../../types/enums'
import { IPageInfo } from '../../types/interface'
import Menu from '../tools/Menu'

export interface IOperateCallbackParams {
  item?: IGetEmployeeListRequest
  filter?: object
  pageInfo?: IPageInfo
}

const EmployeeContent = () => {
  const [isShowDetail, displayDetailPage] = useState<boolean>(false)
  const [employeeList, setEmployeeList] = useState<IGetEmployeeListRequest[]>(
    []
  )
  const [editData, setEditData] = useState<IGetEmployeeListRequest>()
  const [filterData, setFilterData] = useState<object>({})
  const [pageInfo, setPageInfo] = useState<IPageInfo>({ page: 1, size: 10 })
  const [isLoading, showLoading] = useState<boolean>(false)
  const [employeeTotal, setEmployeeTotal] = useState<number>(0)

  const getEmployeeList = async () => {
    showLoading(true)
    const result = await apiCollection.getEmployeeList({
      ...filterData,
      ...pageInfo,
    })

    if (result.success) {
      const { data: list, total } = result.data
      setEmployeeList(list)
      setEmployeeTotal(total)
    }
    showLoading(false)
  }

  const operateCallback = (
    type: EnumOperateType,
    data?: IOperateCallbackParams
  ) => {
    switch (type) {
      case EnumOperateType.Update:
        displayDetailPage(true)
        setEditData(data.item)
        break

      case EnumOperateType.Add:
        displayDetailPage(true)
        break

      case EnumOperateType.Cancel:
        setEditData(null)
        displayDetailPage(false)
        break

      case EnumOperateType.Submit:
        setEditData(null)
        displayDetailPage(false)
        getEmployeeList()
        break

      case EnumOperateType.Filter:
        setFilterData(data.filter)
        setPageInfo((current) => ({ ...current, page: 1 }))
        break

      case EnumOperateType.ChangePage:
        setPageInfo((current) => ({ ...current, ...data.pageInfo }))
        break
    }
  }

  useEffect(() => {
    getEmployeeList()
  }, [pageInfo])

  return (
    <>
      <Menu
        current={EnumMenuTypes.EmployeeList}
        refreshPage={() => {
          operateCallback(EnumOperateType.Cancel)
        }}
      />
      {isShowDetail && (
        <EmployeeDetailContent
          isEdit={!!editData?.Employee_No}
          editData={editData}
          operateCallback={operateCallback}
        />
      )}
      {!isShowDetail && (
        <EmployeeListContent
          list={employeeList}
          total={employeeTotal}
          filterData={filterData}
          isLoading={isLoading}
          pageInfo={pageInfo}
          operateCallback={operateCallback}
        />
      )}
    </>
  )
}

export default EmployeeContent
